import React from 'react';

function LoadingScreen(){
  return (
    <div>
      <h4>Loading...</h4> <i className="fas fa-spinner spin" />
    </div>
  )
}

export default LoadingScreen;
