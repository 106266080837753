import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import classnames from 'classnames';



class ClientDetails extends Component {
  state={
    showBalanceUpdate: false,
    balanceUpdateAmount: '',
  };

  getClientDetails = (client, balanceForm, notesForm) => {
    return <div>
      <div className="row">
        <div className="col-md-6">
          <Link to="/" className="btn">
            <i className="fas fa-arrow-circle-left" />{' '}Back to Dashboard
          </Link>
        </div>
        <div className="col-md-6">
          <div className="btn-group float-right">
            <Link to={`/client/edit/${client.id}`} className="btn btn-dark" style={{width: '75px'}}>
              Edit
            </Link>
            <button className="btn btn-danger" onClick={this.deleteClick} style={{width: '75px'}}>
              Delete
            </button>
          </div>
        </div>
      </div>
      <hr/>
      <div className="card">
        <h3 className="card-header">
          {client.firstName} {client.lastName}
        </h3>
        <div className="card-body">
          <div className="row">
            <div className="col-md-8 col-sm-6">
              <h4>Client Id:{' '} <span className="text-secondary">{client.id}</span></h4>
            </div>
            <div className="col-md-4 col-sm-6">
              <h3 className="pull-right">
                Balance: <span className={classnames({
                'text-danger': client.balance > 0,
                'text-success': client.balance < 0.01,
              })}>${parseFloat(client.balance).toFixed(2)}</span>
                {' '}
                <small>
                  <a href="#" onClick={() => this.setState({showBalanceUpdate: !this.state.showBalanceUpdate })}>
                    <i className="fas fa-pencil-alt"/>
                  </a>
                </small>
              </h3>
              { balanceForm }
            </div>
          </div>
          <hr/>
          <ul className="list-group">
            <li className="list-group-item">
              <b>Contact:</b><br/>
              {client.address}<br/>
              {client.phone}<br/>
              {client.email}
            </li>
            <li className="list-group-item">
              <b>Rate:</b> {client.frequency} @ ${parseFloat(client.cost).toFixed(2)} <br/> <b>Estimated Time:</b> {client.time} hours</li>
            <li style={{listStyleType: 'none'}}>
              { notesForm }
            </li>
          </ul>
        </div>
      </div>
    </div>
  };

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  // Update Notes
  notesSubmit = e => {
    e.preventDefault();
    const { client, firestore } = this.props;
    const { notes } = this.state;
    const clientUpdate = {
      ...client,
      notes: notes,
    };
    firestore.update({ collection: 'clients', doc: client.id }, clientUpdate);
  };

  // Update Balance
  balanceSubmit = e => {
    e.preventDefault();

    const { client, firestore } = this.props;
    const { balanceUpdateAmount } = this.state;
    const clientUpdate = {
      balance: parseFloat(balanceUpdateAmount)
    };
    //update in firestore
    firestore.update({ collection: 'clients', doc: client.id }, clientUpdate);
  };

  // Delete Client
  deleteClick = e => {
    const { client, firestore, history } = this.props;
    firestore.delete({collection: 'clients', doc: client.id})
      .then(() => history.push('/'))
  };

  render() {
    const { client } = this.props;
    const { showBalanceUpdate, balanceUpdateAmount, notes } = this.state;
    const listStyle = {
      listStyleType: 'none',
    };

    const notesForm = (
      <form onSubmit={this.notesSubmit}>
        <div className="input-group">
          {/*<div className="input-group-prepend">*/}
            {/*<input type="submit"*/}
                   {/*value="Update"*/}
                   {/*className="btn btn-outline-dark"*/}
            {/*/>*/}
          {/*</div>*/}
          <textarea type="text"
                 className="form-control"
                 name="notes"
                 placeholder="Job Notes..."
                 defaultValue={(client) ? client.notes : '' }
                 onChange={this.onChange}
          />
          <div className="input-group-append">
            <input type="submit"
                   value="Update"
                   className="btn btn-outline-dark"
            />
          </div>
        </div>
      </form>
    );

    let balanceForm = '';

    if(showBalanceUpdate) {
      balanceForm = (
        <form onSubmit={this.balanceSubmit}>
          <div className="input-group">
            <input type="text"
                   className="form-control"
                   name="balanceUpdateAmount"
                   placeholder="Add New Balance"
                   value={balanceUpdateAmount}
                   onChange={this.onChange}
            />
            <div className="input-group-append">
              <input type="submit"
                     value="Update"
                     className="btn btn-outline-dark"
              />
            </div>
          </div>
        </form>
      )
    } else {
      balanceForm = null;
    }
    return (
      <div>
        {(client) ? this.getClientDetails(client, balanceForm, notesForm) : (<h1>Loading...</h1>)}
      </div>
    )
  }
}


ClientDetails.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect(props => [
    { collection: 'clients', storeAs: 'client', doc: props.match.params.id }
  ]),
  connect(({ firestore: { ordered }}, props) => ({
    client: ordered.client && ordered.client[0]
  }))
)(ClientDetails);
