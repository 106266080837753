import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import NumberFormat from 'react-number-format';

class AddClient extends Component {
  state = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phone: '',
    cost: '',
    frequency: '',
    lastServiceDate: new Date(),
    lastReadableServiceDate: new Date().toLocaleDateString(),
    time: .25,
    balance: '',
  };

  onSubmit = (e) => {
    e.preventDefault();
    const newClient = this.state;
    const { firestore, history } = this.props;
    // if no balance make zero
    if(newClient.balance === ''){
      newClient.balance = 0;
    }
    firestore.add({ collection: 'clients' }, newClient).then(() => history.push('/'));
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  render() {
    const { disableBalanceOnAdd } = this.props.settings;
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <Link to="/" className="btn bt-link">
              <i className="fas fa-arrow-circle-left" />{' '}Back to Dashboard
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-header">Add Client</div>
          <div className="card-body">
            <form onSubmit={ this.onSubmit } >
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input type="text"
                       className="form-control"
                       name="firstName"
                       minLength="2"
                       required
                       onChange={this.onChange}
                       value={this.state.firstName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input type="text"
                       className="form-control"
                       name="lastName"
                       minLength="2"
                       required
                       onChange={this.onChange}
                       value={this.state.lastName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Address</label>
                <input type="text"
                       className="form-control"
                       name="address"
                       minLength="2"
                       onChange={this.onChange}
                       value={this.state.address}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email"
                       className="form-control"
                       name="email"
                       onChange={this.onChange}
                       value={this.state.email}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <NumberFormat
                  format="(###) ###-####"
                  mask="_"
                  name="phone"
                  className="form-control"
                  isNumericString="true"
                  onChange={this.onChange}
                  defaultValue={this.state.phone}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="frequency">Frequency</label>
                <select className="form-control"
                        name="frequency"
                        onChange={this.onChange}
                        value={this.state.frequency}
                >
                  <option value="Single">Single Service</option>
                  <option value="Weekly">Weekly</option>
                  <option value="As_Needed">As Needed</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="time">Estimated Time (hrs)</label>
                <input type="number"
                       className="form-control"
                       name="time"
                       min=".25"
                       max="8"
                       step=".25"
                       onChange={this.onChange}
                       value={this.state.time}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cost">Service Cost</label>
                <input type="text"
                       className="form-control"
                       name="cost"
                       onChange={this.onChange}
                       value={this.state.cost}
                />
              </div>
              <div className="form-group">
                <label htmlFor="balance">Balance</label>
                <input type="text"
                       className="form-control"
                       name="balance"
                       onChange={this.onChange}
                       value={this.state.balance}
                       disabled={disableBalanceOnAdd}
                />
              </div>
              {/* Submit Form */}
              <input type="submit" value="Submit" className="btn btn-primary btn-block"/>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

AddClient.propTypes = {
  firestore: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};



export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    settings: state.settings,
  }))
)(AddClient);
