import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import classnames from 'classnames';
import Sidebar from "../layout/Sidebar";


class Clients extends Component {
  state = {
    totalOwed: null,
  };

  static getDerivedStateFromProps(props) {
    const { clients } = props;
    if(clients) {
      const total = clients.reduce((total, client) => {
        return total + parseFloat(client.balance.toString());
      }, 0);
      return { totalOwed: total }
    }
    return null;
  }

  serviceClient = (client) => {
    console.log(client);
    const { firestore } = this.props;
    const updClient = {
      ...client,
      lastReadableServiceDate: new Date().toLocaleDateString(),
      lastServiceDate: new Date(),
      balance: (parseFloat(client.balance) + parseFloat(client.cost)),
    };
    firestore.update({ collection: 'clients', doc: client.id }, updClient)
  };

  render() {
    const { clients } = this.props;
    const { totalOwed } = this.state;
    if (clients) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <h2><i className="fas fa-users" />Clients{' '}</h2>
            </div>
            <div className="col-md-6">
              {/* todo: add balance here */}
              <h5 className="text-right text-secondary">
                Total Owed{' '}
                <span className="text-primary">
                  ${parseFloat(totalOwed).toFixed(2)}
                </span>
              </h5>
            </div>
          </div>
          <table className="table table-striped">
            <thead className="thead-inverse">
              <tr>
                <th/>
                <th>Name</th>
                <th>Last Service</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Balance</th>
                <th><Sidebar /></th>
              </tr>
            </thead>
            <tbody>
            {clients.map(client => (
              <tr key={client.id}>
                <td className="align-middle text-center"><i className={classnames('fas fa-circle', {
                  'text-success' : (new Date().getTime()/1000 - client.lastServiceDate.seconds) < (86400 * 4),
                  // 'text-warning' : (1 === 1),
                  'text-warning' : (new Date().getTime()/1000 - client.lastServiceDate.seconds) > (86400 * 4),
                  'text-danger' : (new Date().getTime()/1000 - client.lastServiceDate.seconds) > (86400 * 6),
                })} /></td>

                <td>{client.firstName} {client.lastName}</td>
                <td>{client.lastReadableServiceDate}</td>
                <td>{client.address}</td>
                <td>{client.phone}</td>
                <td>${parseFloat(client.balance).toFixed(2)}</td>
                <td>
                  <div className="btn-group">
                    <Link to={`/client/${client.id}`}
                          className="btn btn-secondary btn-sm"
                          title="View Client Details..."
                    >
                      <i className="fas fa-arrow-circle-right" /> Details
                    </Link>
                    <button className="btn btn-primary btn-sm"
                            title="Service Client..."
                            onClick={() => this.serviceClient(client)}
                    >
                      <i className="fas fa-check" /> Service
                    </button>
                  </div>

                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div>
          <h4><i className="fas fa-spinner fa-spin"/>&nbsp;Loading...</h4>
        </div>)
    }
  }
}

Clients.propTypes = {
  firestore: PropTypes.object.isRequired,
  clients: PropTypes.array,
};

export default compose(
  firestoreConnect([{ collection: 'clients'}]),
  connect((state) => ({
    clients: state.firestore.ordered.clients,
  }))
)(Clients);
