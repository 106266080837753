import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

class EditClient extends Component {
  constructor(props){
    super(props);
    this.firstNameInput = React.createRef();
    this.lastNameInput = React.createRef();
    this.addressInput = React.createRef();
    this.emailInput = React.createRef();
    this.phoneInput = React.createRef();
    this.frequencyInput = React.createRef();
    this.timeInput = React.createRef();
    this.costInput = React.createRef();
    this.balanceInput = React.createRef();
  }

  onSubmit = e => {
    e.preventDefault();
    const { client, firestore, history } = this.props;

    // Update Client
    const updClient = {
      firstName: this.firstNameInput.current.value,
      lastName: this.lastNameInput.current.value,
      address: this.addressInput.current.value,
      email: this.emailInput.current.value,
      phone: this.phoneInput.current.value,
      frequency: this.frequencyInput.current.value,
      time: this.timeInput.current.value,
      cost: this.costInput.current.value,
      balance: (this.balanceInput.current.value === '') ? 0 : this.balanceInput.current.value,
    };

    // update client in firestore
    firestore.update({ collection: 'clients', doc: client.id }, updClient)
      .then(history.push('/'))
  };

  render() {
    const { client } = this.props;
    const { disableBalanceOnEdit } = this.props.settings;
    return(
      <div>
        {client ? (
          <div className="card">
            <div className="card-header">Edit Client</div>
            <div className="card-body">
              <form onSubmit={ this.onSubmit } >
                <div className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <input type="text"
                         className="form-control"
                         name="firstName"
                         minLength="2"
                         required
                         ref={this.firstNameInput}
                         defaultValue={client.firstName}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input type="text"
                         className="form-control"
                         name="lastName"
                         minLength="2"
                         required
                         ref={this.lastNameInput}
                         defaultValue={client.lastName}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input type="text"
                         className="form-control"
                         name="address"
                         minLength="2"
                         required
                         ref={this.addressInput}
                         defaultValue={client.address}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">email</label>
                  <input type="email"
                         className="form-control"
                         name="email"
                         ref={this.emailInput}
                         defaultValue={client.email}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input type="text"
                         className="form-control"
                         name="phone"
                         minLength="10"
                         required
                         ref={this.phoneInput}
                         defaultValue={client.phone}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="frequency">Frequency</label>
                  <select className="form-control"
                          name="frequency"
                          ref={this.frequencyInput}
                          defaultValue={client.frequency}
                  >
                    <option value="Single">Single Service</option>
                    <option value="Weekly">Weekly</option>
                    <option value="As_Needed">As Needed</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="time">Estimated Time (hrs)</label>
                  <input type="number"
                         className="form-control"
                         name="time"
                         min=".25"
                         max="8"
                         step=".25"
                         ref={this.timeInput}
                         defaultValue={client.time}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cost">Service Cost</label>
                  <input type="text"
                         className="form-control"
                         name="cost"
                         ref={this.costInput}
                         defaultValue={client.cost}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="balance">Balance</label>
                  <input type="text"
                         className="form-control"
                         name="balance"
                         ref={this.balanceInput}
                         defaultValue={client.balance}
                         disabled={disableBalanceOnEdit}
                  />
                </div>
                {/* Submit Form */}
                <input type="submit" value="Submit" className="btn btn-primary btn-block"/>
              </form>
            </div>
          </div>
        ) : <h1>Loading</h1>}
      </div>
    )
  }
}

EditClient.propTypes = {
  firestore: PropTypes.object.isRequired,
};

export default compose(
  firestoreConnect(props => [
    { collection: 'clients', storeAs: 'client', doc: props.match.params.id }
  ]),
  connect(({ firestore: { ordered }, settings}) => ({
    client: ordered.client && ordered.client[0],
    settings,
  }))
)(EditClient);